import React from 'react';
import './Subtitles.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Table} from 'react-bootstrap';
// import { Row, Col} from 'react-bootstrap';
// import video1 from '../../../../assets/images/card5.png'
// import { BiDotsVerticalRounded } from "react-icons/bi";
// import { MdEdit } from "react-icons/md";
// import { SiBookmeter } from "react-icons/si";
// import { AiFillPlayCircle } from "react-icons/ai";
import video1 from '../../../../assets/images/card5.png'
const Subtitles = () => {
    return(
        <>
            <div className='dashboardPage'>
                <div className='dashboardHeader'>
                    <h2>Subtitles</h2>
                </div>
                <div className='subtitles themeAnalytics'>
                    <Tabs>
                        <TabList>
                            <Tab>All</Tab>
                            <Tab>Drafts</Tab>
                            <Tab>Published</Tab>
                        </TabList>

                        <TabPanel>
                        <div className='themeTable'>
                        <Table resposinve>
                        <thead>
                            <tr>
                            <th><p>Video</p></th>
                            <th><p>Languages</p></th>
                            <th><p>Modified Date</p></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='videoInfo videoPara'>
                                        <div className='videoImg'>
                                            <img className='img-fluid' src={video1} alt='Video'></img>
                                        </div>
                                        
                                        <div>
                                            <p className='analytic-P'>Video 20221205071229 Soldier</p>
                                            <p className='light-p'>Integer ac interdum lacus. Nunc porta semper lacus a varius. Pellentesque habitant morbi tristique senectus et netus et malesuada fames.</p>
                                            
                                        </div>
                                    </div>
                                </td>
                                <td><p>English</p></td>
                                <td><p>09-12-2022</p></td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='videoInfo'>
                                        <div className='videoImg'>
                                            <img className='img-fluid' src={video1} alt='Video'></img>
                                        </div>
                                        
                                        <div>
                                            <p className='analytic-P'>Video 20221205071229 Soldier</p>
                                            <p className='light-p'>Integer ac interdum lacus. Nunc porta semper lacus a varius. Pellentesque habitant morbi tristique senectus et netus et malesuada fames.</p>
                                            
                                        </div>
                                    </div>
                                </td>
                                <td><p>Arabic</p></td>
                                <td><p>09-12-2022</p></td>
                            </tr>
                        </tbody>
                        </Table>
                    </div>
                        </TabPanel>
                        <TabPanel>
                        <h2>Any content 2</h2>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>
    )
}
export default Subtitles