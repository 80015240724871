import React from 'react';
import './Explore.css'
import { Link } from "react-router-dom";
import {Container} from 'react-bootstrap'
import card1 from '../../../../assets/images/card1.png'
import card2 from '../../../../assets/images/card2.png'
import card3 from '../../../../assets/images/card3.png'
import card4 from '../../../../assets/images/card4.png'
import card5 from '../../../../assets/images/card5.png'
import card6 from '../../../../assets/images/card6.png'
import card7 from '../../../../assets/images/card7.png'
import card8 from '../../../../assets/images/card8.png'
import card9 from '../../../../assets/images/card9.png'
import card10 from '../../../../assets/images/card10.png'
import user1 from '../../../../assets/images/user1.png'
import user2 from '../../../../assets/images/user2.png'
import user3 from '../../../../assets/images/user3.png'
import user4 from '../../../../assets/images/user4.png'
import user5 from '../../../../assets/images/user5.png'
import user6 from '../../../../assets/images/user6.png'
import user7 from '../../../../assets/images/user7.png'
import user8 from '../../../../assets/images/user8.png'
import user9 from '../../../../assets/images/user9.png'
import user10 from '../../../../assets/images/user10.png'
const SiteCards = () => {
    const exploreData = [
        { id: 1, card:card1, user:user1, name: "The Blockchain Revolution", views: "576,972 views" },
        { id: 2, card:card2, user:user2, name: "Crypto investment rules", views: "576,972 views" },
        { id: 3, card:card3, user:user3, name: "Gate.io – CryptoMode", views: "576,972 views"},
        { id: 4, card:card4, user:user4, name: "Its Own Blockchain", views: "576,972 views" },
        { id: 5, card:card5, user:user5, name: "The Blockchain Revolution", views: "576,972 views" },
        { id: 6, card:card6, user:user6, name: "The Blockchain Revolution", views: "576,972 views" },
        { id: 7, card:card7, user:user7, name: "Crypto investment rules", views: "576,972 views" },
        { id: 8, card:card8, user:user8, name: "Gate.io – CryptoMode", views: "576,972 views" },
        { id: 9, card:card9, user:user9, name: "Its Own Blockchain", views: "576,972 views" },
        { id: 10, card:card10, user:user10, name: "The Blockchain Revolution", views: "576,972 views" }
      ];
  console.log(exploreData[1]);
    return(
        <>
            <section className='explore'>
                <Container fluid>
                    <div className='wrapVideos'>
                        {exploreData.map(exploreData => {
                            return (       
                                <div className='exploreCard' key={exploreData.id}>
                                    <div className='exploreImg'>
                                        <img className='img-fluid' src={exploreData.card} alt='Explore Vodeos'></img>
                                    </div>
                                    <div className='cardFooter'>
                                        <div className='userImg me-3'>
                                            <img className='img-fluid' src={exploreData.user} alt='Explore Vodeos'></img>
                                        </div>
                                        <div className='videoReview'>
                                            <p>{exploreData.name}</p>
                                            <span>{exploreData.views}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        
                    </div>
                    <div className='text-center w-100 d-block'>
                            <button class="orange-btn text-uppercase mx-auto">Load more</button>
                        </div>
                </Container>
            </section>

        </>
    )
}
export default SiteCards