import React from 'react';
import Header from '../Header/Header'
import Banner from './Banner/Banner'
import Explore from './Explore/Explore'
import Footer from '../Footer/Footer'
import Works from './Works/Works'
import Feature from './Feature/Feature';

const Home = () => {
    return(
        <>
            <Header/>
            <Banner/>
            <Explore/>
            <Works/>
            <Feature/>
            <Footer/>
 
        </>
    )
}
export default Home