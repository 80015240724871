import React from 'react';
import './siteTabs.css';
import { Row, Col} from 'react-bootstrap';

const AnalyticsContent = () => {
    return(
        <>
            <div className='dashboardPage'>
                <div className='analyticContent'>
                <div className='analyticGraph'>
                    <div className='analyticsBar'>
                        <div className='analyticsBarBox'>
                            <span>Watch Time (Hours)</span>
                            <h4>32412</h4>
                        </div>
                        <div className='analyticsBarBox'>
                            <span>Average View Duration</span>
                            <h4>124</h4>
                        </div>
                    </div>
                   
                </div>
                <Row>
                        <Col md={6}>
                            <div className='box'></div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
export default AnalyticsContent