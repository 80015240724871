import React from 'react'
import {useState} from 'react'
import './DashboardSidebar.css'
import '../DasboardPage/DasboardPage.css'
import dashbordLogo from '../../../../assets/images/logo.png'
import user from '../../../../assets/images/user.png'
import home from '../../../../assets/images/home.png'
import { SiAcclaim } from "react-icons/si"
// import DasboardPage from '../DasboardPage/DasboardPage'
// import Content from '../Content/Content'
import Analytics from '../Analytics/Analytics'
// import Subtitles from '../Subtitles/Subtitles'


const DashboardSidebar = () => {
    const [dashboardBar, setDashboardBar] = useState(true)
    const [show, setShow] = useState(false)
    return(
        <>
            <div className='dashboardBody'>
                <div className='dashboard-Sidebar'>
                    <div className={dashboardBar ? 'sidebarLogo' : ' logo-change'} 
                    onClick={()=>setDashboardBar(!dashboardBar)}><SiAcclaim></SiAcclaim></div>
                        {
                        dashboardBar ? 
                            <div className='dashboardFlex' onMouseOver={()=>setShow(!show)}>
                                <div>
                                    <div className='dashboardLogo'>
                                        <img className='img-fluid' src={dashbordLogo} alt='biiview'></img>
                                    </div>
                                    <div className='user'>
                                        <div className='userBlock'>
                                            <img className='img-fluid' src={user} alt='user'></img>
                                            <h3>Elizabeth Fox</h3>
                                            <p>245 Videos Uploaded</p>
                                        </div>
                                        <ul className='dashboard-ul'>
                                            <li><a href='/dashboard' className='active'> <img className='img-fluid me-3' src={home} alt='dashboard'></img> Dashboard</a></li>
                                            <li><a href='/content' className=''> <img className='img-fluid me-3' src={home} alt='content'></img> Content</a></li>
                                            <li><a href='/analytics' className=''> <img className='img-fluid me-3' src={home} alt='analysis'></img> Analytics</a></li>
                                            <li><a href='/subtitles' className=''> <img className='img-fluid me-3' src={home} alt='subtitles'></img> Subtitles</a></li>
                                            <li><a href='/settings' className=''> <img className='img-fluid me-3' src={home} alt='settings'></img> Settings</a></li>
                                        </ul>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            :
                            <div className='dashboard-sidebar'>
                              
                             
                            </div>
                        }
                </div>
                <div className={dashboardBar ? 'dashboard-sitePages dashboard-main-width' : 'dashboard-sitePages sitePages-width'}>
                    {/* <DasboardPage/> */}
                    {/* <Content/> */}
                    <Analytics/>
                    {/* <Subtitles/> */}
                </div>
            </div>
        </>
    )
}
export default DashboardSidebar