import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './components/Frontend/Home/Home';
import Signup from './components/Frontend/Forms/Signup/Signup';
import Login from './components/Frontend/Forms/Login/Login';
import Member from './components/Frontend/Forms/Member/Member';
import Package from './components/Frontend/Forms/Package/Package';
import CardDetails from './components/Frontend/Forms/CardDetails/CardDetails';
import ForgotPass from './components/Frontend/Forms/ForgotPass/ForgotPass';
import MainSidebar from './components/Frontend/MainSidebar/MainSidebar';
import DashboardSidebar from './components/Frontend/Dashboard/Sidebar/DashboardSidebar';
import Content from './components/Frontend/Dashboard/Content/Content';
import Analytics from './components/Frontend/Dashboard/Analytics/Analytics';
import Subtitles from './components/Frontend/Dashboard/Subtitles/Subtitles';

function App() {

  return (
    <>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element = {<Home />}></Route>
              <Route path="/signup" element={<Signup />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/forgot" element={<ForgotPass />}></Route>
              <Route path="/member" element={<Member />}></Route>
              <Route path="/package" element={<Package />}></Route>
              <Route path="/card-details" element={<CardDetails />}></Route>
              <Route path="/mainSidebar" element={<MainSidebar />}></Route>
              <Route path="/dashboardSidebar" element={<DashboardSidebar />}></Route>
              <Route path="/Content" element={<Content />}></Route>
              <Route path="/analytics" element={<Analytics />}></Route>
              <Route path="/subtitles" element={<Subtitles />}></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;