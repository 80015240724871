import React from 'react';
import './Analytics.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Row, Col} from 'react-bootstrap';
import { IoCaretDownSharp } from "react-icons/io5";
import AnalyticsContent from '../TabComponents/AnalyticsContent';

const Analytics = () => {
    return(
        <>
            <div className='dashboardPage'>
                <div className='dashboardHeader'>
                    <h2>Channel analytics</h2>
                </div>
                <div className='videoAnalytics themeAnalytics'>
                    <Tabs>
                        <TabList>
                            <Tab>Overview</Tab>
                            <Tab>Reach</Tab>
                            <Tab>Engagement</Tab>
                            <Tab>Audience</Tab>
                            <Tab>Identified Objects</Tab>
                        </TabList>

                        <TabPanel>
                            <Row>
                                <Col lg={8} className=''>
                                    <div className='analyticGraph'>
                                        <div className='analyticsBar'>
                                            <div className='analyticsBarBox'>
                                                <span>Views</span>
                                                <h4>32412</h4>
                                            </div>
                                            <div className='analyticsBarBox'>
                                                <span>Watch Time (Hours)</span>
                                                <h4>38:34</h4>
                                            </div>
                                            <div className='analyticsBarBox'>
                                                <span>Object Recognized</span>
                                                <h4>124</h4>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className='box'>
                                        <div className='realtimeBox'>
                                            <div>
                                                <h5>Realtime</h5>
                                                <p>Updating live</p>
                                            </div>
                                            <div>
                                            <h5>Realtime</h5>
                                            <div className='searchByDays align-items-center'>
                                                <p className='me-1 mb-0'>Views - Last 60 minutes</p>
                                                <IoCaretDownSharp />
                                            </div>
                                            </div>
                                        </div>
                                        <div className='realtimeBox-btm'>
                                            <p className='mb-0'>-60 (min)</p>
                                            <p className='mb-0'>Now</p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </TabPanel>
                        <TabPanel>
                            <AnalyticsContent/>
                        </TabPanel>
                        <TabPanel>
                            <AnalyticsContent/>
                        </TabPanel>

                    </Tabs>
                </div>
            </div>
        </>
    )
}
export default Analytics