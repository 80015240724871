import React from 'react';
import './Feature.css'
import {Container, Row, Col} from 'react-bootstrap'
import { TiTick } from "react-icons/ti";
import feature1 from '../../../../assets/images/feature1.png'
import feature2 from '../../../../assets/images/feature2.png'

const Feature = () => {
    return(
        <>
            <section className='feature'>
                <Container fluid className='customBox'>
                    <div className='sectionTitle'>
                        <h2>Amazing Features</h2>
                        <p className='sofia-p'>Duis rhoncus dui venenatis consequat porttitor. Etiam aliquet congue consequat. In posuere, nunc sit amet laoreet blandit, urna sapien imperdiet lectus, et molestie sem tortor quis dui. Donec molestie nisi iaculis sodales mollis.</p>
                    </div>
                    <div className='mainF-Row1'>
                        <Row className='featureRow'>
                            <Col md={5} className='mb-md-0 mb-4'>
                                <div className='featureImg'>
                                <div className='wrap-path-lines'>
                            <span className='lines'>
                                <span className='line2'><span className='line3'></span></span>
                            </span>
                        </div>
                                    <img className='img-fluid' src={feature1} alt='Amazing Features'></img>
                                </div>
                            </Col>
                            <Col md={7}>
                                <div className='classify'>
                                    <h2>Data <span className='theme'>Classification</span></h2>
                                    <p className='outfit-p'>Duis rhoncus dui venenatis consequat porttitor. Etiam aliquet congue consequat. In posuere, nunc sit amet laoreet blandit, urna sapien imperdiet lectus, et molestie sem tortor quis dui. Donec molestie nisi iaculis.</p>

                                        <ul>
                                            <li><TiTick/> <p>In posuere, nunc sit amet laoreet blandit</p></li>
                                            <li><TiTick/> <p>In posuere, nunc sit amet laoreet blandit</p></li>
                                        </ul>
        
                                    <button className='orange-btn text-uppercase'>learn more</button>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <div className='bar'></div>
                        </div>
                    </div>
                    <Row className='dataLabelRow'>
                        <Col md={8} className='mb-md-0 mb-4'>
                            <div className='dots'>
                                <span></span><span></span><span></span><span></span><span></span><span></span><span></span>
                            </div>
                            <div className='dataLabelImg'>
                                <div className='featureImg2'>
                                    <img className='img-fluid' src={feature2} alt='Amazing Features'></img>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='wrapData'>
                                <h2>Data <span className='theme'>Labeling</span></h2>
                                <p className='outfit-p'>Duis rhoncus dui venenatis consequat porttitor. Etiam aliquet congue consequat. In posuere, nunc sit amet laoreet blandit, urna sapien imperdiet lectus, et molestie sem tortor quis dui. Donec molestie nisi iaculis.</p>
                                <button className='orange-btn text-uppercase'>learn more</button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
export default Feature