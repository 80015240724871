import React from 'react';
import '../Forms.css'
import './Package.css'
import {Container, Row, Col } from 'react-bootstrap'
import formLogo from '../../../../assets/images/logo.png'
import {useNavigate, Link} from 'react-router-dom'
const Package = () => {
      const navigate = useNavigate();
      const navigateToCardDetails = () => {
          navigate('/card-details');
        };
    return(
        <>
            <section className='theme-form Login'>
                <div className='form-overlay'></div>
                    <div className='biiview-form'>
                        <Container fluid className='formContainer'>
                            <div className='formLogo'>
                                <Link to='/'> <img className='img-fluid' src={formLogo} alt='BiiView'></img> </Link>
                            </div>
                                <div className='formStyle package'>
                                    <div className='formHeader'>
                                        <h2>Choose your Package</h2>
                                    </div>
                                    <Row>
                                        <Col md={4} className='mb-md-0 mb-3'>
                                            <div className='package-card'>
                                                <div>
                                                    <p className='packageTitle'>FREE FOREVER</p>
                                                    <h3 className='packageH3'>$0<sub className='h3Sub'>/month</sub></h3>
                                                    <ul className='package-ul'>
                                                        <li><p>Short-length video.</p></li>
                                                        <li><p>Can Upload Up to 300MB.</p></li>
                                                        <li><p>Can upload unlimited videos.</p></li>
                                                    </ul>
                                                </div>
                                                <div><button className='orange-btn text-uppercase mt-0' onClick={navigateToCardDetails}>SELECT</button></div>
                                            </div>
                                        </Col>
                                        <Col md={4} className='mb-md-0 mb-3'>
                                            <div className='package-card'>
                                                <div>
                                                    <p className='packageTitle'>INDIVIDUAL</p>
                                                    <ul className='packageCardHeader'>
                                                        <div>
                                                            <h3 className='packageH3'>$15<sub className='h3Sub'>/month</sub></h3>
                                                        </div>
                                                        <div>
                                                            <h3 className='packageH3'>$153<sub className='h3Sub'>/year</sub></h3>
                                                        </div>
                                                    </ul>
                                                    <ul className='package-ul'>
                                                        <li><p>Short-length video.</p></li>
                                                        <li><p>Can Upload Up to 300MB.</p></li>
                                                        <li><p>Can upload unlimited videos.</p></li>
                                                    </ul>
                                                </div>
                                                <div><button className='orange-btn text-uppercase mt-0' onClick={navigateToCardDetails}>SELECT</button></div>
                                            </div>
                                        </Col>
                                        <Col md={4}>
                                            <div className='package-card'>
                                                <div>
                                                    <p className='packageTitle'>CORPORATE</p>
                                                    <ul className='packageCardHeader'>
                                                        <div>
                                                            <h3 className='packageH3'>$25<sub className='h3Sub'>/month</sub></h3>
                                                        </div>
                                                        <div>
                                                            <h3 className='packageH3'>$255<sub className='h3Sub'>/year</sub></h3>
                                                        </div>
                                                    </ul>
                                                    <ul className='package-ul'>
                                                        <li><p>Upload a full-length video. </p></li>
                                                        <li><p>Can upload a maximum 2GB.</p></li>
                                                        <li><p>Can upload unlimited videos.</p></li>
                                                    </ul>
                                                </div>
                                                <div><button className='orange-btn text-uppercase mt-0' onClick={navigateToCardDetails}>SELECT</button></div>
                                            </div>
                                        </Col>
                                    </Row>
                                  
                                </div>

                                
                                </Container>
                                </div>
            </section>
        </>
    )
}
export default Package