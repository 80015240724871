import React from 'react';
import '../Forms.css'
import {Container, Form } from 'react-bootstrap'
import formLogo from '../../../../assets/images/logo.png'
import {useNavigate, Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
const Signup = () => {
    function onChange(value) {
        console.log("Captcha value:", value);
      }
      const navigate = useNavigate();
      const navigateToLogin = () => {
          // 👇️ navigate to /Login
          navigate('/login');
        };
    return(
        <>
            <section className='theme-form Login'>
                <div className='form-overlay'></div>
                    <div className='biiview-form'>
                        <Container fluid className='formContainer'>
                            <div className='formLogo'>
                                <Link to='/'> <img className='img-fluid' src={formLogo} alt='BiiView'></img> </Link>
                            </div>
                                <Form className='formStyle'>
                                    <div className='formHeader'>
                                        <h2>Create New Account</h2>
                                        <div className='d-flex align-items-end justify-content-center'><p   
                                        className='mb-0'>EXISTING USER?</p><button onClick={navigateToLogin} 
                                        className='link ms-2'>LOGIN</button></div>
                                    </div>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Full Name</Form.Label>
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your Name"></input>
                                    </Form.Group>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Email Address</Form.Label>
                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-
                                        describedby="emailHelp" placeholder="Enter Your Email Address"></input>
                                    </Form.Group>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Password</Form.Label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Enter Your Password"></input>
                                    </Form.Group>
                                    <Form.Group className='formGroup' controlId="formBasicEmail">
                                        <Form.Label>Verify Password</Form.Label>
                                        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Re-Enter Password"></input>
                                    </Form.Group>
                                    <div className='theme-capcha'>
                                        <ReCAPTCHA
                                            sitekey="Your client site key"
                                            onChange={onChange}
                                        />
                                        <button className='orange-btn text-uppercase'>sign up</button>
                                    </div>
                                </Form>
                                <div className='d-flex align-items-end justify-content-center'><p className='mb-0 outer-p'>I agree with the</p><Link to='/' className='link ms-2'> Terms & Conditions.</Link></div>
                                
                                </Container>
                                </div>
            </section>
        </>
    )
}
export default Signup