import React from 'react';
import './Content.css';
// import '../DashboardPage/DashboardPage.css'
import { Table} from 'react-bootstrap';
import video1 from '../../../../assets/images/card5.png'
import { BiDotsVerticalRounded } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import { SiBookmeter } from "react-icons/si";
import { AiFillPlayCircle } from "react-icons/ai";

const Content = () => {
    return(
        <>
            <div className='dashboardPage'>
                <div className='dashboardHeader d-flex justify-content-between align-item-center'>
                    <h2>Content</h2> <button class="orange-btn text-uppercase mt-0">UPLOAD VIDEO</button>
                </div>
                <div className='content px40'>
                    <div className='themeTable'>
                        <Table resposinve>
                        <thead>
                            <tr>
                            <th>
                                <div className="form-check checkTable">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                </div>
                            </th>
                            <th>
                            <p>Video</p>
                            </th>
                            <th><p>Visibility</p></th>
                            <th><p>Restrictions</p></th>
                            <th><p>Date</p></th>
                            <th><p>Views</p></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className="form-check checkTable">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                </td>
                                <td>
                                    <div className='videoInfo'>
                                        <div className='videoImg'>
                                            <img className='img-fluid' src={video1} alt='Video'></img>
                                        </div>
                                        
                                        <div>
                                            <p>Video 20221205071229 Soldier</p>
                                            <p>Major Tank</p>
                                            <div className='videoIcons'>
                                                <span><MdEdit/></span>
                                                <span><SiBookmeter/></span>
                                                <span><AiFillPlayCircle/></span>
                                                <span><BiDotsVerticalRounded/></span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td><p>Draft</p></td>
                                <td><p>None</p></td>
                                <td><p>09-12-2022</p></td>
                                <td><p>12.43M</p></td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="form-check checkTable">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                </td>
                                <td>
                                    <div className='videoInfo'>
                                        <div className='videoImg'>
                                            <img className='img-fluid' src={video1} alt='Video'></img>
                                        </div>
                                        
                                        <div>
                                            <p>Video 20221205071229 Soldier</p>
                                            <p>Major Tank</p>
                                            <div className='videoIcons'>
                                                <span><MdEdit/></span>
                                                <span><SiBookmeter/></span>
                                                <span><AiFillPlayCircle/></span>
                                                <span><BiDotsVerticalRounded/></span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td><p>Draft</p></td>
                                <td><p>None</p></td>
                                <td><p>09-12-2022</p></td>
                                <td><p>12.43M</p></td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="form-check checkTable">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                </td>
                                <td>
                                    <div className='videoInfo'>
                                        <div className='videoImg'>
                                            <img className='img-fluid' src={video1} alt='Video'></img>
                                        </div>
                                        
                                        <div>
                                            <p>Video 20221205071229 Soldier</p>
                                            <p>Major Tank</p>
                                            <div className='videoIcons'>
                                                <span><MdEdit/></span>
                                                <span><SiBookmeter/></span>
                                                <span><AiFillPlayCircle/></span>
                                                <span><BiDotsVerticalRounded/></span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td><p>Draft</p></td>
                                <td><p>None</p></td>
                                <td><p>09-12-2022</p></td>
                                <td><p>12.43M</p></td>
                            </tr>
                        </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Content